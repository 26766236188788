import React from 'react'
import Layout from '../templates/Main'
import Heading from '../modules/Heading'
import { Box, Flex, Text, Image } from 'rebass/styled-components'

const Privacy = ({ params }) => {
    return (
        <Layout id={params.id}>
            <Heading size='h1' text='Privacy' />
            <Flex variant='container'>
                <Box width='30%'>
                    <Text as='h2' variant='h2'mx='sm' >
                        COOKIES POLICY
                    </Text>
                    <Text as='p' variant='copy' color='grey' mx='sm' my='sm'>
                        Our website uses cookies to distinguish you from other users of our website. This helps us to provide you with a good experience when you browse our website and also allows us to improve our site.
                    </Text>
                    <Text as='p' variant='copy' color='grey' mx='sm' my='sm'>
                        A cookie is a small file of letters and numbers that we store on your browser or the hard drive of your computer if you agree. Cookies contain information that is transferred to your computer's hard drive.
                    </Text>
                    <Text as='p' variant='copy' color='grey' mx='sm' my='sm'>
                        There are the following types of cookies:
                    </Text>
                    <Text as='h4' variant='h4' color='grey' mx='sm' my='sm'>
                        Strictly necessary cookies.
                    </Text>

                    <Text as='p' variant='copy' color='grey' mx='sm' my='sm'>
                        These are cookies that are required for the operation of our website. They include, for example, cookies that enable you to log into secure areas of our website, use a shopping cart or make use of e-billing services.                    </Text>
                    <Text as='h4' variant='h4' color='grey' mx='sm' my='sm'>
                        Analytical/performance cookies.
                    </Text>
                    <Text as='p' variant='copy' color='grey' mx='sm' my='sm'>
                        They allow us to recognise and count the number of visitors and to see how visitors move around our website when they are using it. This helps us to improve the way our website works, for example, by ensuring that users are finding what they are looking for easily.
                    </Text>
                    <Text as='h4' variant='h4' color='grey' mx='sm' my='sm'>
                        Functionality cookies.
                    </Text>
                    <Text as='p' variant='copy' color='grey' mx='sm' my='sm'>
                        These are used to recognise you when you return to our website. This enables us to personalise our content for you, greet you by name and remember your preferences (for example, your choice of language or region).
                    </Text>
                    <Text as='h4' variant='h4' color='grey' mx='sm' my='sm'>
                        Targeting cookies

                    </Text>
                    <Text as='p' variant='copy' color='grey' mx='sm' my='sm'>
                        These cookies record your visit to our website, the pages you have visited and the links you have followed. We will use this information to make our website and the advertising displayed on it more relevant to your interests. We may also share this information with third parties for this purpose.
                    </Text>
                    <Text as='p' variant='copy' color='grey' mx='sm' mt='sm' mb='sm'>
                        You can find more information about the individual cookies we use and the purposes for which we use them in the table below:
                    </Text>
                    <Text as='p' variant='copy' color='grey' mx='sm' my='sm'>
                        Please note that third parties (including, for example, advertising networks and providers of external services like web traffic analysis services) may also use cookies, over which we have no control. These cookies are likely to be analytical/performance cookies or targeting cookies.
                    </Text>
                    <Text as='p' variant='copy' color='grey' mx='sm' my='sm'>
                        You block cookies by activating the setting on your browser that allows you to refuse the setting of all or some cookies. However, if you use your browser settings to block all cookies (including essential cookies) you may not be able to access all or parts of our site.
                    </Text>
                    <Text as='p' variant='copy' color='grey' mx='sm' mt='sm' mb='xl'>
                        Except for essential cookies, all cookies will expire after 2 hours.
                    </Text>
                </Box>
                <Box width='70%'>
                    <Image width='100%' src='https://res.cloudinary.com/kidston/image/upload/v1633334091/restorations/miura.png' />
                </Box>
            </Flex>
        </Layout>
    )
}

export default Privacy
